import Logo from '../../assets/sev/logo.png';
import Favicon from '../../assets/sev/favicon.png';
import { Theme, ThemeDark } from '../../themes/sev';
import { defaultConfig } from '../default-config';
import { Config } from '../types';

export const config: Config = {
  authConfig: {
    Keycloak: {
      ...defaultConfig.authConfig.Keycloak,
      clientId: 'reseller-sev',
    },
  },
  dataConfig: {...defaultConfig.dataConfig, api: process.env.REACT_APP_SEV + '/api'},
  logo: Logo,
  favicon: Favicon,
  title: 'Sev',
  theme: Theme,
  themeDark: ThemeDark,
  showIpex: defaultConfig.showIpex,
  showUvam: defaultConfig.showUvam,
  isLeafReseller: true,
  currentReseller: 'sev',
};

import { ThemeOptions } from '@material-ui/core';
import { defaultTheme } from './default';

export const Theme: ThemeOptions = {
  ...defaultTheme,
  palette: {
    type: 'light',
    primary: {
      main: '#427fba',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#ede7f6',
    },
    background: {
      default: '#F3F3F4',
      paper: '#ffffff',
    },
    ...defaultTheme.palette,
  },
};

export const ThemeDark: ThemeOptions = {
  ...defaultTheme,
  palette: {
    type: 'dark',
    primary: {
      main: '#427fba',
    },
    secondary: {
      main: '#2b343b',
      light: '#ede7f6',
    },
    background: {
      default: '#1E272E',
      paper: '#2b343b',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)',
    },
    ...defaultTheme.palette,
  },
};
